(function () {
  class LoanInterestRefund extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.status = '#loan_interest_refund_status';

      // BY Class
      this.ui.fileInput = '.custom-file-input';
    };

    loadComponents() {
      this.configure();

      this.needFormValidation();
      this.loadStatus();
      this.loadCustomFileInput();
      $(this.ui.status).change();
    };

    loadStatus() {
      const operation = this;

      $(operation.ui.status).on('change', function () {
        var selected = $(this).find('option:selected').val();

        if (selected == 'managed') {
          $(operation.ui.fileInput).attr('required', true);
          $(operation.ui.fileInput).closest('.form-group').find('label.js-label').addClass('required');
        } else {
          $(operation.ui.fileInput).removeAttr('required');
          $(operation.ui.fileInput).closest('.form-group').find('label.js-label').removeClass('required');
        };
      });
    };
  };

  window.LoanInterestRefund = LoanInterestRefund;
})();
